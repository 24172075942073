import React from "react";
import LazyLoad from 'react-lazyload';
import config from '../../config.js';


const BackgroundVideo = ({fill, cover, videoUrl}) => (
	<div className="video-container">
		<div className="background-video-container">
			<LazyLoad offset={config.loadOffset} once>
				<video src={videoUrl} loop playsInline autoPlay data-objectfit="cover" muted />
			</LazyLoad>
		</div>
	</div>
);

export default BackgroundVideo;
