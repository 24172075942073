import React, { useEffect, useState } from "react";
import BackgroundVideo from "../repeating_elements/background_video.js";

function BrowserView({video, mobile, fill, additionalClasses}) {
	const [isMobileView, setMobileView] = useState(null);
	useEffect(function mount() {
		const mediaQuery = window.matchMedia('(min-width: 760px)')
		if(mediaQuery.matches) {
			setMobileView(true);
		}
	});
	if(isMobileView) {
		return <BackgroundVideo videoUrl={video} additionalClasses={additionalClasses} mobile={mobile} fill={fill} />
	}
	return null;
}
export default BrowserView;

