import React, { Component } from "react";
import LazyLoad from 'react-lazyload';
import config from '../../config.js';
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const BackgroundImage = ({className, url, mobileURL, alt, loading = ""}) => (
	<div className="image-contain">
		<LazyLoad offset={config.loadOffset} once>
			<picture>
				{ mobileURL
					? <React.Fragment>
						<link rel="preload" as="image" href={mobileURL}></link>
						<link rel="preload" as="image" href={url}></link>
						<source srcSet={url} media="(min-width: 900px)" />
						<img decoding="async" src={mobileURL} alt={alt} loading={loading}/>
					</React.Fragment>
					: <img src={url} alt={alt} loading={loading}/>
				}
			</picture>
		</LazyLoad>
	</div>
);

export default BackgroundImage;
